@use "@styles/variables" as variable;
@use "@styles/mixins" as mixin;
.section {
  order: 4;
}
.prouvedWrapper {
  padding: 30px 0;
  max-width: 930px;
  margin: auto;
  width: calc(100% - 30px);
}
.prouved {
  padding: 15px;
  border: 1.5px solid variable.$primaryColor;
  border-radius: 10px;
  .title {
    font-family: variable.$Freeman;
    font-size: variable.$size26;
    color: variable.$primaryColor;
    line-height: 52px;
    display: flex;
    gap: 10px;
    span {
      padding-bottom: 6px;
      display: block;
    }
  }
  .text {
    font-size: variable.$size16;
    font-family: variable.$primaryFontBold;
    line-height: 30px;
    color: variable.$primaryColor;
  }
  .logos {
    display: flex;
    gap: 15px 40px;
    justify-content: center;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
    img {
      height: 35px;
    }
  }
}

@include mixin.mediaDesktopSize() {
  .prouved {
    .text {
      padding-left: 75px;
      padding-right: 20px;
    }
    .logos {
      gap: 40px;
    }
  }
}
