/* |\\\\\\ variables |\\\\\\  */
// colors
$primaryColor: #5a008c;
$primarylightColor: #c1a0d4;
$fadePrimaryColor: rgba(90, 0, 140, 49%);
$buttonColor: #5a008c;
$buttonColorHover: #5a008c;
$orangeColor: #ff5f55;
$alertColor: #ff000d;
$bgProductHover: #fdd9ee;
$counterErrorColor: #ff5f55;
$GreyColor: #929292;
$fadeGreyColor: #ededed;
$textColor: #350d5a;
$popinProductTab: #eae5e1;
$accountBackgroundColor: #fcf6ff;
$accountLinkColor: #daf5d9;
$fadeGreenColor: rgba(118, 191, 114, 0.2);
$filterColor: #c77698;
$whiteColor: #ffffff;
$blackColor: #000000;
$purple: #e4d1ee;
$bgColor: #fcf6ff;
$greenColor: #d1f1da;
$greenLightColor: #d0f4ef;
$yellowColor: #f5eb46;
$yellowLightColor: #feefa9;
$darkGreenColor: #72c693;
$darkYellowColor: #eb9702;
$darkOrangeColor: #f0bc6f;
$aquaBreezeColor: #96e6dc;
$redColor: #fc4644;
$pinkColor: #f6a9c0;
$secondaryColor: #cd8cdc;

//font-family
$titlesFont: "PassionOne";
$primaryFont: "SFProDisplay";
$primaryFontHeavy: "SFProDisplay-Heavy";
$primaryFontBold: "SFProDisplay-Bold";
$primaryFontSemiBold: "SF Pro Display";
$primaryFontBlack: "SFProDisplay-Black";
$secondaryFont: "PassionOne";
$GellatioFont: "Gellatio";
$CaveatBrush: "Caveat Brush";
$Freeman: "Freeman";

//font size
$bigSize: 30px;
$smallSize: 12px;
$mediumSize: 17px;
$size10: 10px;
$size11: 11px;
$size12: 12px;
$size13: 13px;
$size14: 14px;
$size15: 15px;
$size16: 16px;
$size18: 18px;
$size19: 19px;
$size20: 20px;
$size21: 21px;
$size22: 22px;
$size26: 26px;
$size28: 28px;
$size24: 24px;
$size25: 25px;
$size34: 34px;
$size36: 36px;
$size50: 50px;
$size48: 48px;
$mediumLineHeight: 17px;

//Widths
$boxedLayout: 980px;
