@use "variables" as variable;

// Media query responsive
@mixin mediaFullDesktop() {
  @media (min-width: 1600px) {
    @content;
  }
}
@mixin mediaDesktop() {
  @media (min-width: 1300px) {
    @content;
  }
}
@mixin mediaDesktopSize() {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin mediaTabletSize() {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin mediaSmallTabletSize() {
  @media (min-width: 630px) {
    @content;
  }
}
@mixin mediaSmallMobileSize() {
  @media (min-width: 481px) {
    @content;
  }
}
@mixin mediaLaptopSize() {
  @media (min-width: 1024px) and (max-height: 730px) {
    @content;
  }
}

/// @deprecated Not mobile first
@mixin mediaTablet() {
  @media (max-width: 1023px) {
    @content;
  }
}

/// @deprecated Not mobile first
@mixin mediaSmallTab() {
  @media (max-width: 767px) {
    @content;
  }
}

/// @deprecated Not mobile first
@mixin mediaMobile() {
  @media (max-width: 629px) {
    @content;
  }
}

/// @deprecated Not mobile first
@mixin mediaSmallMobile() {
  @media (max-width: 480px) {
    @content;
  }
}

// Repetitif styles
@mixin coverAll {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@mixin fixedElement {
  position: fixed;
  @include coverAll;
}
@mixin bgBlack {
  background: rgba(0, 0, 0, 0.6);
}
@mixin bgCover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@mixin centerBox {
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
@mixin buttonPrimary {
  font-family: variable.$Freeman;
  background-color: variable.$buttonColor;
  border: 1px solid transparent;
  line-height: 45px;
  color: #ffffff;
  font-size: variable.$size20;
  text-align: center;
  border-radius: 15px;
  width: 100%;
  max-width: 100%;
  letter-spacing: 1px;
  background-image: none;
  text-transform: uppercase;
  border: 0;
  padding: 0;
  text-align: center;
  text-indent: 0;
  cursor: pointer;
  display: block;
  position: relative;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: variable.$buttonColorHover;
  }
}
@mixin buttonSecondary {
  font-family: variable.$Freeman;
  background-color: variable.$secondaryColor;
  border: 1px solid variable.$secondaryColor;
  line-height: 45px;
  color: variable.$whiteColor;
  font-size: variable.$size20;
  text-align: center;
  border-radius: 15px;
  width: 100%;
  max-width: 100%;
  letter-spacing: 1px;
  background-image: none;
  text-transform: uppercase;
  padding: 0;
  text-align: center;
  text-indent: 0;
  cursor: pointer;
  display: block;
  position: relative;
  box-shadow: 1px 1px 3px -1px variable.$secondaryColor;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: variable.$whiteColor;
    color: variable.$secondaryColor;
  }
}

@mixin buttonSecondaryGreen {
  font-family: variable.$titlesFont;
  font-size: 26px;
  color: #76bf72;
  display: inline-block;
  padding: 10px 35px 10px 20px;
  border: 2px solid #76bf72;
  background-color: #ffffff;
  border-radius: 5px;
  letter-spacing: 1px;
  position: relative;
  img {
    width: 17px;
    height: 13px;
    position: absolute;
    right: 8px;
    left: auto;
    top: 50%;
    margin-top: -4px;
  }
  &:hover {
    background-color: #ffffff;
    color: variable.$buttonColorHover;
    border: 2px solid variable.$buttonColorHover;
  }
}

@mixin buttonSecondaryBlue {
  font-family: variable.$primaryFontHeavy;
  background-color: #1977f3;
  line-height: 45px;
  color: #ffffff;
  font-size: variable.$size16;
  text-align: center;
  border-radius: 15px;
  width: 100%;
  max-width: 100%;
  letter-spacing: 1px;
  background-image: none;
  text-transform: uppercase;
  border: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  box-shadow: 2px 2px 6px 1px #ccc;
  img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: #2e81ec;
  }
}

@mixin buttonDefault {
  @include buttonPrimary;
  background-color: variable.$primaryColor;
  font-family: variable.$Freeman;
  position: relative;
  border-radius: 50px;
  box-shadow: none;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: rgba($color: variable.$primaryColor, $alpha: 0.7);
  }
}
@mixin buttonDefaultSecondary {
  @include buttonPrimary;
  background-color: variable.$whiteColor;
  font-family: variable.$Freeman;
  color: variable.$primaryColor;
  position: relative;
  border-radius: 50px;
  box-shadow: none;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: variable.$whiteColor;
  }
}
